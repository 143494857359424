<template>
  <PageContainer center-vertically="true">
    <PageTitle register-screen="true">
      {{ trans('FORM_TITLE:VerifyEmail') }}
    </PageTitle>
    <t-card variant="auth">
      <div class="w-full flex flex-col mb-3 gap-3">
        <div>
          {{ trans('TEXT:VerifyEmail-1') }}
        </div>
        <div class="font-semibold">
          {{ trans('TEXT:VerifyEmail-2') }}
        </div>
        <Hint variant="Info" class="flex flex-col">
          <div v-if="userData">{{ trans('TEXT:VerifyEmail-3') }}</div>
	        <div v-else>{{ trans('TEXT:VerifyEmail-7') }}</div>
	        {{ trans('TEXT:VerifyEmail-4') }}
        </Hint>
      </div>
      <div class="w-full flex flex-col lg:flex-row gap-3 items-end lg:items-start">
	      <TextField
			      v-if="!userData"
			      v-model="email"
			      rules="email|required|max:255"
			      class="w-full"
			      :placeholder="trans('LABEL:Email')">
	      </TextField>
        <Button
          variant="buttonRed"
          :text="trans('BUTTON:ResendVerificationEmail')"
          :loading="resendVerificationLoading"
          :disabled="resendLinkDisable"
          @clicked="resendVerificationAction"
        />
      </div>
    </t-card>
  </PageContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Button from "@/utils/crud/components/Button";
import Hint from "@/views/content/components/Hint";
import router from "@/router";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import TextField from "@/utils/crud/components/field-types/Text.vue";

export default {
  name: 'VerifyEmail',
  data () {
    return {
      email: '',
    }
  },
  components: {
	  TextField,
    PageTitle,
    PageContainer,
    Button,
    Hint
  },
  computed: {
    ...mapGetters('account', ['resendVerificationLoading', 'resendLinkDisable', 'userData'])
  },
  watch: {
    userData: {
      immediate: true,
      handler(val) {
				if(val) {
					this.email = this.userData.email;
				}
        if(val && val.emailVerifiedAt) {
          router.push(val.loginScreen, () => {
            this.success({message: this.trans('TEXT:VerifyEmailSuccess')})
          });
        }
      }
    },
  },
  methods: {
    ...mapActions('account', ['logout', 'resendVerificationEmail']),
    ...mapActions('alert', ['success']),
    resendVerificationAction() {
      this.resendVerificationEmail({email: this.email});
    },
    logoutAction() {
      this.logout();
    }
  },
  mounted() {
		if(this.userData) {
			this.email = this.userData.email;
		}
  }
}
</script>
